
export default {
  name: 'OrganismLocation',
  props: {
    locationData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      showSidebar: false,
      queriedAddress: '',
      searchResult: {
        success: true,
        text: '',
        addressText: '',
        directionsResponse: {},
        queryResults: null
      }
    }
  },
  computed: {
    searchDisabled() {
      return !this.queriedAddress
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    hasSearchResult() {
      return this.searchResult.text !== '' && this.searchResult.queryResults
    },
    hasSearchError() {
      return this.searchResult.text !== '' && !this.searchResult.success
    }
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar
    },
    showDirectionsForMarkerId(id) {
      this.$refs.map.triggerMarkerById(id)
    },
    handleSearch() {
      this.searchResult = this.$refs.map.distanceFrom(this.queriedAddress)
      this.$refs.searchInput.resetInput()
    },
    focusSearch() {
      this.$refs.searchInput.focus()
    }
  }
}
